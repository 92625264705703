import React from "react"
import { Link } from "gatsby"

/*Material-ui */
import { Grid, Typography } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

/*Local files */
//import ImageBack from "../images/OSXKWY0.png"
import ImageBack from "../images/fondo3.png"

import Reloj from "../images/reloj.svg"

const useStyles = makeStyles(theme => ({
  ContenedorMain: {
    backgroundImage: `url(${ImageBack})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "950px",
    MozBackgroundSize: "cover",
    "@media (max-width: 720px)": {
      backgroundPosition: "40%",
    },
  },
  ContenedorMain2: {
    backgroundColor: "#FFB91C",
    width: "100%",
    height: "849px",
    MozBackgroundSize: "cover",
    boxShadow: "0px 4px 16px #0000008C !important",
  },
  TitleWhite: {
    color: "#385099",
    marginTop: "3rem",
    fontSize: "55px",
    font: "800 45px/55px Montserrat",
    "@media (max-width: 720px)": {
      fontSize: "30px",
    },
  },
  Parrafo: {
    color: "#385099",
    textAlign: "center",
    fontSize: "25px",
    font: "500 17px/20px Montserrat",
    "@media (max-width: 720px)": {
      width: "80%",
      fontSize: "20px",
    },
  },
  ContainerReloj: {
    marginTop: "6rem",
  },
  Titulo1: {
    color: "#FFFFFF",
    marginTop: "1rem",
    fontSize: "55px",
    font: "800 45px/55px Montserrat",
    "@media (max-width: 720px)": {
      width: "80%",
      fontSize: "30px",
      textAlign: "center",
    },
  },
  Subtitulo: {
    color: "#FFFFFF",
    marginTop: "1rem",
    fontSize: "37px",
    font: "800 30px/37px Montserrat",
    "@media (max-width: 720px)": {
      width: "80%",
      fontSize: "25px",
      textAlign: "center",
    },
  },
  containerButton: {
    marginTop: "1.5rem",
  },
  image: {},
}))

const StyledButton = withStyles({
  root: {
    background:
      "transparent linear-gradient(90deg, #4267CB 0%, #16B1CB 100%) 0% 0% no-repeat padding-box",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 49,
    width: "193px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
    "@media (max-width: 720px)": {
      height: 60,
      width: "260px",
      borderRadius: "50px",
      font: "800 18px Montserrat",
    },
  },
  label: {
    textTransform: "none",
    fontFamily: "Helvetica",
    font: "800 17px/20px Montserrat",
    textDecoration: "none !important",
  },
})(Button)

const Footer = () => {
  const classes = useStyles()
  return (
    <div>
      <Grid className={classes.ContenedorMain}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.TitleWhite}>
              No hay pretextos
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <p className={classes.Parrafo}>
              Aplica cuestionarios en la plataforma web.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.ContenedorMain2}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.ContainerReloj}
          >
            <img src={Reloj} alt="Reloj" className={classes.image} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.Titulo1}>
                No lo dejes para el último segundo
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.Subtitulo}>
                Aún estas a tiempo de evitar multas.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.containerButton}
            >
              <Link to="/Paquetes" style={{ textDecoration: "none" }}>
                <StyledButton id="LEAD2">Ver paquetes</StyledButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer
